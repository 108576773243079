import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url: "home",
  },
  {
    id: "slider",
    title: "Slider",
    type: "item",
    icon: "columns",
    url: "slider",
  },
  {
    id: "categories",
    title: "Categories",
    type: "item",
    icon: "grid",
    url: "categories",
  },
  {
    id: "products",
    title: "Products",
    type: "item",
    icon: "columns",
    url: "products",
  },
  {
    id: "productRequests",
    title: "Product Requests",
    type: "item",
    icon: "phone",
    url: "product-requests",
  },
  {
    id: "events",
    title: "Events",
    type: "item",
    icon: "tag",
    url: "events",
  },
  {
    id: "news",
    title: "News",
    type: "item",
    icon: "cast",
    url: "news",
  },
  {
    id: "cases",
    title: "Cases",
    type: "item",
    icon: "briefcase",
    url: "cases",
  },
  {
    id: "careers-items",
    title: "Careers",
    type: "item",
    icon: "command",
    url: "careers-items",
  },
  {
    id: "customers",
    title: "Customers",
    type: "item",
    icon: "users",
    url: "customers",
  },
  {
    id: "about-slider",
    title: "About Slider",
    type: "item",
    icon: "columns",
    url: "about-slider",
  },
  // {
  //   id: 'types',
  //   title: 'Types',
  //   type: 'item',
  //   icon: 'bar-chart-2',
  //   url: 'types'
  // },
  // {
  //   id: 'locations',
  //   title: 'Locations',
  //   type: 'item',
  //   icon: 'map-pin',
  //   url: 'locations'
  // },
  // {
  //   id: 'amenities',
  //   title: 'Amenities',
  //   type: 'item',
  //   icon: 'plus-square',
  //   url: 'amenities'
  // },
  // {
  //   id: 'compounds',
  //   title: 'Compounds',
  //   type: 'item',
  //   icon: 'grid',
  //   url: 'compounds'
  // },
  // {
  //   id: 'units',
  //   title: 'Units',
  //   type: 'item',
  //   icon: 'tag',
  //   url: 'units'
  // },
  {
    id: "content",
    title: "Content Editor",
    type: "section",
    icon: "image",
    children: [
      {
        id: "home",
        title: "Home Page",
        type: "item",
        icon: "home",
        url: "content/home",
      },
      {
        id: "about",
        title: "About Page",
        type: "item",
        icon: "command",
        url: "content/about",
      },
      {
        id: "careers",
        title: "Careers Page",
        type: "item",
        icon: "command",
        url: "content/careers",
      },
      {
        id: "events",
        title: "Events Page",
        type: "item",
        icon: "tag",
        url: "content/events",
      },
      {
        id: "news",
        title: "News Page",
        type: "item",
        icon: "cast",
        url: "content/news",
      },
      {
        id: "cases",
        title: "Cases Page",
        type: "item",
        icon: "briefcase",
        url: "content/cases",
      },
      {
        id: "contact",
        title: "Contact Page",
        type: "item",
        icon: "phone",
        url: "content/contact",
      },
      {
        id: "products",
        title: "Products Page",
        type: "item",
        icon: "grid",
        url: "content/products",
      },
      {
        id: "large-products",
        title: "Large Products Page",
        type: "item",
        icon: "grid",
        url: "content/large-products",
      },
      {
        id: "config",
        title: "Social Media",
        type: "item",
        icon: "cpu",
        url: "content/config",
      },
    ],
  },
  {
    id: "site-data",
    title: "Site Data",
    type: "section",
    icon: "image",
    children: [
      {
        id: "newsletter",
        title: "Newsletter",
        type: "item",
        icon: "voicemail",
        url: "site-data/newsletter",
      },
      {
        id: "contact-us",
        title: "Contact Us",
        type: "item",
        icon: "cast",
        url: "site-data/contact",
      },
      {
        id: "register-interest",
        title: "Register Interest",
        type: "item",
        icon: "award",
        url: "site-data/register-interest",
      },
    ],
  },
];
